<template>
  <v-app id="inspire">
    <v-main>
      <v-container class="fill-height">
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            sm="8"
            md="4"
          >
            <v-card class="elevation-12">
              <h1 class="text-center mt-6">
                DMIS
              </h1>
              <v-snackbar
                v-model="snackbar"
                centered
                :color="color"
                :timeout="timeout"
              >
                {{ text }}
                <template v-slot:action="{ attrs }">
                  <v-btn
                    color="#4268b1"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                  >
                    Close
                  </v-btn>
                </template>
              </v-snackbar>
              <p class="text-center font-weight-light font-italic mt-10" v-if="resetarea">The Token was sent into your email ...</p>
              <v-card-text>
                <v-form v-if="resetarea">
                  <v-text-field
                    v-model="username"
                    label="Username"
                    name="username"
                    dense
                    outlined
                    type="text"
                    color="grey"
                    filled
                    disabled
                  />
                  <validation-provider
                    rules="required"
                    vid="confirmation"
                    v-slot="{ errors }"
                    name="New Password">
                  <v-text-field
                    v-model="newpassword"
                    label="New Password"
                    name="New Password"
                    dense
                    :error-messages="errors"
                    outlined
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show ? 'text' : 'password'"
                    @click:append.prevent="show = !show"
                    color="grey"
                    filled
                  />
                  </validation-provider>
                  <validation-provider
                    v-slot="{ errors }"
                    rules="confirmed:confirmation"
                    name="Confirmed Password">
                  <v-text-field
                    v-model="passwordConfirmation"
                    label="Confirm Password"
                    name="Confirmed Password"
                    dense
                    :error-messages="errors"
                    outlined
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show1 ? 'text' : 'password'"
                    @click:append.prevent="show1 = !show1"
                    color="grey"
                    filled
                    hint="password complexity, at least: 8 characters, one capital letter and avoid sequential numbers(123...)"
                    persistent-hint
                  />
                  </validation-provider>
                  <v-text-field
                    v-model="token"
                    label="Token"
                    name="token"
                    type="token"
                    dense
                    outlined
                    color="grey"
                    filled
                    disabled
                  />
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  color="#4268b1"
                  width="100px"
                  @click="resetPassword"
                  v-if="!success"
                >
                  Reset
                </v-btn>
                <v-spacer />
                <v-btn
                  outlined
                  link
                  text
                  @click="backToLogin"
                >
                  Back To Login
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
const par = new URLSearchParams(window.location.search)
const username = par.get('username')
const token = par.get('token')

export default {
  data () {
    return {
      newpassword: '',
      passwordConfirmation: '',
      username: username,
      token: token,
      loginId: null,
      resetarea: true,
      show: false,
      show1: false,
      snackbar: false,
      color: null,
      text: null,
      timeout: 10000
    }
  },

  methods: {

    resetPassword () {
      this.$authenticationService.resetPassword(this.username, this.newpassword, this.token)
        .then(response => {
          if (response.status === 'OK') {
            this.snackbar = true
            this.color = 'green'
            this.text = 'Your password has been reset successfully'
            this.resetarea = false
            setTimeout(() => {
              this.backToLogin()
            }, 5000)
          } else {
            this.snackbar = true
            this.text = 'Error! ' + response.message
            this.color = 'red'
          }
        })
    },
    validate () {
      this.$refs.form.validate()
    },
    backToLogin () {
      this.$router.push('/login')
    }
  }

}

</script>
